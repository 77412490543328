import React from "react"
import { graphql } from "gatsby"
import { Box, Grid, Heading } from "theme-ui"
import { GatsbyImage } from "jam-cms"
import Parser from "html-react-parser"

// import app components

import Layout from "../../components/Layout"
import Textarea from "../../components/Textarea"

const AboutPageTemplate = (props) => {
  const {
    data: {
      wpPage: {
        seo,
        template: {
          acf: { section1, section2 },
        },
      },
    },
  } = props

  return (
    <Layout {...props} seo={seo}>
      {/*
      Section 1 -----------------------------------------------------------------------------------------------
      */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "100%",
          pb: "5px",
        }}
      >
        <Grid
          sx={{
            position: "relative",
            height: "100%",
            gridRow: 1,
            gap: 0,
            width: "100%",
          }}
          columns={[1, "1fr 1fr", "1fr 1fr"]}
        >
          <Box
            sx={{
              position: "relative",
              bg: "black",
              gridRowStart: 1,
              gridColumnStart: 1,
              width: "100%",
              display: "flex",
              height: ["300px", "400px", "450px"],
              color: "white",
            }}
          >
            {section1?.headline && (
              <Heading
                variant="text.sectionHeading"
                sx={{
                  mt: "auto",
                  mr: "auto",
                  ml: [4, 5, 6],
                  mb: 5,
                }}
              >
                {Parser(section1?.headline)}
              </Heading>
            )}
          </Box>

          <Box
            sx={{
              position: "relative",
              gridRowStart: [2, 1],
              gridColumnStart: [1, 2],
              width: "100%",
              height: ["300px", "400px", "450px"],
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "400px", "450px"],
              },
            }}
          >
            {section1?.image1 && (
              <GatsbyImage
                image={section1?.image1}
                alt={section1?.image1?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>

          <Box
            sx={{
              position: "relative",

              bg: "white",
              gridRowStart: [4, 2],
              gridColumnStart: [1, 2],
              width: "100%",
              height: "100%",
              minHeight: "300px",
              pt: ["30px", "90px"],
              pl: ["30px", "45px"],
              pr: ["30px", "105px"],
              pb: "30px",
            }}
          >
            {section1?.text && <Textarea content={section1?.text} />}
          </Box>

          <Box
            sx={{
              position: "relative",
              gridRowStart: [3, 2],
              gridColumnStart: 1,
              pt: ["5px", 0],
              width: "100%",
              height: ["300px", "400px", "450px"],
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "400px", "450px"],
              },
            }}
          >
            {section1?.image2 && (
              <GatsbyImage
                image={section1?.image2}
                alt={section1?.image2?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
        </Grid>
      </Box>

      {/*
      Section 2-----------------------------------------------------------------------------------------------
      */}
      <Box bg={"white"} sx={{ mb: "30px" }}>
        <Box
          sx={{
            display: ["flex", "grid"],
            flexDirection: "column",
            gridTemplateColumns: [1, "1fr 1fr 1fr 1fr 1fr 1fr"],
            gridTemplateRows: [null, "1fr 1fr 1fr 1fr 1fr 1fr 1fr"],
            gap: 0,
          }}
        >
          <Box
            sx={{
              gridRowStart: 1,
              gridRowEnd: 4,
              gridColumnStart: 1,
              gridColumnEnd: 4,
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section2.image1 && (
              <GatsbyImage
                image={section2.image1}
                alt={section2.image1?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridRowStart: 1,
              gridRowEnd: 3,
              gridColumnStart: 4,
              gridColumnEnd: 6,
              pb: [0, "5px", "5px"],
              pl: [0, "5px", "5px"],
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section2.image2 && (
              <GatsbyImage
                image={section2.image2}
                alt={section2.image2?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridRowStart: 3,
              gridRowEnd: 5,
              gridColumnStart: 4,
              gridColumnEnd: 6,
              pb: [0, "5px", "5px"],
              pl: [0, "5px", "5px"],
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section2.image3 && (
              <GatsbyImage
                image={section2.image3}
                alt={section2.image3?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridRowStart: 5,
              gridRowEnd: 7,
              gridColumnStart: 4,
              gridColumnEnd: 6,
              pb: [0, "5px", "5px"],
              pl: [0, "5px", "5px"],
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section2.image4 && (
              <GatsbyImage
                image={section2.image4}
                alt={section2.image4?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridRowStart: 3,
              gridRowEnd: 5,
              gridColumnStart: 6,
              gridColumnEnd: 7,
              pb: [0, "5px", "5px"],
              pl: [0, "5px", "5px"],
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section2.image5 && (
              <GatsbyImage
                image={section2.image5}
                alt={section2.image5?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>

          <Box
            sx={{
              gridRowStart: 4,
              gridRowEnd: 8,
              gridColumnStart: 1,
              gridColumnEnd: 4,
              width: "100%",
              bg: "white",
              height: "100%",
              minHeight: "300px",
              pt: ["30px", "30px", "90px"],
              pr: ["30px", "30px", "40px"],
              pl: ["30px", "30px", "106px"],
              pb: "30px",
            }}
          >
            {section1?.text && <Textarea content={section2?.text} />}
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export const CollectionQuery = graphql`
  query AboutPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
      template {
        ... on WpTemplate_About {
          templateName
          acf {
            fieldGroupName
            section1 {
              fieldGroupName
              headline
              text
              image1 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
              image2 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
            }
            section2 {
              fieldGroupName
              text
              image1 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
              image2 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
              image3 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
              image4 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
              image5 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
            }
            section3 {
              fieldGroupName
              text
              headline
              link {
                target
                title
                url
              }
            }
            section4 {
              fieldGroupName
              image1 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
              image2 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
              image3 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
              image4 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AboutPageTemplate
